import React from 'react'
import IonIcon from './IonIcon'

const CloseCircleIcon = props => (
  <IonIcon {...props}>
    <title>Close Circle</title>
    <path d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z" />
    <line x1="320" y1="320" x2="192" y2="192" />
    <line x1="192" y1="320" x2="320" y2="192" />
  </IonIcon>
)

export default CloseCircleIcon
