import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'components/Button'
import useNotification from 'hooks/useNotification'
import useAddMemberMutation from 'hooks/mutations/useAddMember'
import useRemoveMemberMutation from 'hooks/mutations/useRemoveMember'

const ManageMembers = ({ members = [] }) => {
  const { appId } = useParams()
  const [email, setEmail] = useState('')
  const [addMember, { loading: loadingAddMember }] = useAddMemberMutation()
  const [removeMember] = useRemoveMemberMutation()
  const { notify } = useNotification()

  return (
    <div className="bg-white shadow-xs rounded px-8 py-6 my-2">
      <form
        className="mb-4"
        onSubmit={async e => {
          e.preventDefault()
          try {
            await addMember({ id: appId, email })
          } catch {
            notify('error', 'User not found.')
            return
          }
          setEmail('')
          notify('info', 'Successfully added a member.')
        }}
      >
        <label
          className="block text-gray-700 text-xs font-bold mb-2"
          htmlFor="name"
        >
          Name
        </label>
        <div className="flex">
          <input
            className="flex-1 mr-1 text-base md:text-xs bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            type="text"
            placeholder="Email"
            value={email}
            onChange={({ target: { value } }) => {
              setEmail(value)
            }}
          />
          <Button disabled={loadingAddMember || !email.trim()}>Add</Button>
        </div>
      </form>
      {Boolean(members.length) && (
        <div className="mb-4 border rounded">
          {members.map((member, idx) => (
            <form
              key={member.id}
              className={`flex justify-center items-center py-1 px-2 ${
                idx > 0 ? 'border-t' : ''
              }`}
              onSubmit={async e => {
                e.preventDefault()
                await removeMember({ id: appId, email: member.email })
                notify('info', 'Successfully removed a member.')
              }}
            >
              <span className="flex-1">{member.email}</span>
              <Button size="s">Remove</Button>
            </form>
          ))}
        </div>
      )}
    </div>
  )
}

export default ManageMembers
