import React from 'react'

const IonIcon = ({ size = 32, style = {}, className = '', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    className={`icon ${className}`}
    style={style}
    {...props}
  />
)

export default IonIcon
