import React from 'react'
import IonIcon from './IonIcon'

const CodeDownloadIcon = props => (
  <IonIcon {...props}>
    <title>code-download</title>
    <polyline points="160 368 32 256 160 144" />
    <polyline points="352 368 480 256 352 144" />
    <polyline points="192 288.1 256 352 320 288.1" />
    <line x1="256" y1="160" x2="256" y2="336.03" />
  </IonIcon>
)

export default CodeDownloadIcon
