import React, { createContext, useReducer } from 'react'
import reducer from './reducer'
import { notify, removeNotification } from 'components/Notifications/actions'

const NotificationsContext = createContext({
  notifications: [],
  dispatch: () => {}
})

export const NotificationsProvider = ({ children }) => {
  const [notifications, dispatch] = useReducer(reducer, [])

  return (
    <NotificationsContext.Provider
      value={{
        notify: (...args) => {
          const action = notify(...args)

          dispatch(action)

          return action.payload.id
        },
        removeNotification: id => dispatch(removeNotification(id)),
        hideNotification: id => dispatch(removeNotification(id)),
        notifications
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsContext
