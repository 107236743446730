import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import CurrentUserContext from 'components/CurrentUserContext'
import HamburgerIcon from 'components/icons/Hamburger'
import CodeDownloadIcon from 'components/icons/CodeDownload'
import Button, { ButtonLink } from 'components/Button'
import { isDevelopment } from 'utils'
import Dropdown, { DropdownItem } from 'components/Dropdown'

const SIGN_OUT = gql`
  mutation SignOut {
    signOut
  }
`

const Layout = ({ children, transparent }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [signOut] = useMutation(SIGN_OUT)
  const headerBorder = transparent ? 'border-gray-100' : 'border-gray-600'
  const footerBorder = transparent ? 'border-gray-100' : 'border-gray-600'

  return (
    <div className="flex flex-col min-h-screen">
      <nav
        className={`flex items-center justify-between flex-wrap p-2 border-b ${headerBorder}`}
      >
        <div className="flex items-center flex-shrink-0 mr-6">
          <Link
            to="/"
            className="font-semibold text-purple-900 text-xl tracking-tight"
          >
            Annedesk
          </Link>
        </div>
        <div className="block md:hidden">
          <Dropdown
            items={
              currentUser.email ? (
                <>
                  <DropdownItem to="/installation">Installation</DropdownItem>
                  <DropdownItem
                    onClick={async () => {
                      await signOut()
                      localStorage.removeItem('token')
                      window.location.reload()
                    }}
                  >
                    Sign out
                  </DropdownItem>
                </>
              ) : (
                <>
                  <DropdownItem to="/sign-up">Sign up</DropdownItem>
                  <DropdownItem
                    to={isDevelopment() ? '/force-sign-in' : '/sign-in'}
                  >
                    Sign in
                  </DropdownItem>
                </>
              )
            }
          >
            {({ open, setOpen }) => (
              <button
                className="flex items-center p-1 hover:border-purple"
                onClick={() => setOpen(!open)}
              >
                <HamburgerIcon
                  style={{
                    fill: 'none',
                    stroke: '#d6bcfa',
                    strokeLinecap: 'round',
                    strokeMiterlimit: 10,
                    strokeWidth: '24px'
                  }}
                  size="2em"
                />
              </button>
            )}
          </Dropdown>
        </div>
        <div className="hidden md:block">
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div className="text-sm lg:flex-grow flex items-center">
              {currentUser.email ? (
                <>
                  <Link
                    className="inline-block text-purple-700 mr-3"
                    to="/installation"
                  >
                    <CodeDownloadIcon
                      style={{
                        fill: 'none',
                        stroke: '#6b46c1',
                        strokeWidth: '24px'
                      }}
                    />
                  </Link>
                  <Button
                    size="xs"
                    onClick={async () => {
                      await signOut()
                      localStorage.removeItem('token')
                      window.location.reload()
                    }}
                  >
                    Sign out
                  </Button>
                </>
              ) : (
                <>
                  <ButtonLink size="xs" className="mr-3" to="/sign-up">
                    Sign up
                  </ButtonLink>
                  <ButtonLink
                    size="xs"
                    className="mr-3"
                    to={isDevelopment() ? '/force-sign-in' : '/sign-in'}
                  >
                    Sign in
                  </ButtonLink>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      {children}
      <div className={`Footer px-1 py-px border-t ${footerBorder}`}>
        © 2020 Annedesk, Inc. All rights reserved.
      </div>
    </div>
  )
}

export default Layout
