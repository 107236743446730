import React, { useEffect } from 'react'
import CloseButton from './CloseButton'
import Container from './Container'
import Icon from './Icon'
import Message from './Message'
import useNotification from 'hooks/useNotification'

const Notification = ({ notification }) => {
  let timer
  const { hideNotification } = useNotification()
  const remove = () => {
    hideNotification(notification.id)
  }
  const scheduleHide = () => {
    timer = setTimeout(remove, notification.timeout)
  }
  const cancelHide = () => {
    clearTimeout(timer)
  }

  useEffect(() => {
    if (!notification.sticky) {
      scheduleHide()
    }

    return () => {
      cancelHide()
      notification.onClose(notification)
    }
  }, [notification])

  return (
    <div
      className="w-notification flex items-center p-2 overflow-hidden rounded border shadow"
      onMouseOver={cancelHide}
      onMouseOut={notification.sticky ? null : scheduleHide}
      onClick={() => notification.onClick(notification)}
    >
      <Icon kind={notification.type} renderIcon={notification.renderIcon} />
      <Message>{notification.message}</Message>
      {notification.dismissable && (
        <CloseButton
          onClick={e => {
            e.stopPropagation()
            remove()
          }}
        />
      )}
    </div>
  )
}

export default Notification
