import React from 'react'
import IonIcon from './IonIcon'

const CheckCircleIcon = props => (
  <IonIcon {...props}>
    <title>Check Circle</title>
    <path d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z" />
    <polyline points="352 176 217.6 336 160 272" />
  </IonIcon>
)

export default CheckCircleIcon
