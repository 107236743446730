import React, { useEffect, useState } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import Layout from 'components/Layout'
import { isDevelopment } from 'utils'

const snippet = (baseUrl, appId) => `
"use strict";
!function() {
  var t = window.anneDesk = [];
  if (!t.init) {
    if (t.invoked) return void (window.console && console.error && console.error("Annedesk snippet included twice."));
    t.invoked = !0,
      t.load = function(t) {
        anneDesk.appId = t;
        var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
        o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = '${baseUrl}/loader.js';
        var i = document.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(o, i);
      };
  }
}();
anneDesk.SNIPPET_VERSION = '0.0.1';
anneDesk.load('${appId}');
`

const LIST_ALL_APPS = gql`
  query ListAllApps {
    apps {
      id
      name
    }
  }
`

const Installation = () => {
  const [selectedAppId, setSelectedAppId] = useState('YOUR_APP_ID')
  const { data: { apps = [] } = {}, loading } = useQuery(LIST_ALL_APPS)
  const baseUrl = isDevelopment()
    ? 'http://localhost:4002'
    : 'https://annedesk-widget.netlify.app'

  useEffect(() => {
    const [app] = apps
    if (app) {
      setSelectedAppId(app.id)
    }
  }, [apps])

  return (
    <Layout noHScreen>
      <div className="container m-auto px-8 py-6">
        <h2 className="text-purple-700 text-xl mb-4">Installation</h2>
        <p className="text-xs my-2">
          To use Annedesk widget, insert below script into {`<body>`} tag in
          your html.
        </p>
        {!loading && !!apps.length && (
          <>
            <label
              className="block tracking-wide text-xxxs font-bold mb-2"
              htmlFor="app-select"
            >
              Select your app to install
            </label>
            <div className="relative flex-1 md:w-full">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="app-select"
                disabled={loading}
                onChange={e => setSelectedAppId(e.target.value)}
                value={selectedAppId}
              >
                {loading ? (
                  <option>Loading...</option>
                ) : (
                  apps.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))
                )}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </>
        )}

        <div className="container">
          <SyntaxHighlighter language="javascript">
            {snippet(baseUrl, selectedAppId)}
          </SyntaxHighlighter>
        </div>
      </div>
    </Layout>
  )
}

export default Installation
