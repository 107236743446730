import React from 'react'
import IonIcon from './IonIcon'

const HamburgerIcon = props => (
  <IonIcon {...props}>
    <title>Hamburger</title>
    <line x1="80" y1="160" x2="432" y2="160" />
    <line x1="80" y1="256" x2="432" y2="256" />
    <line x1="80" y1="352" x2="432" y2="352" />
  </IonIcon>
)

export default HamburgerIcon
