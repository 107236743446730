import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_APP = gql`
  query GetApp($id: String!) {
    app(id: $id) {
      id
      name
      hostname
      channels {
        id
      }
      members {
        id
        email
      }
      permissions
    }
  }
`

const useGetAppQuery = (id, options) =>
  useQuery(GET_APP, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only',
    ...options
  })

export default useGetAppQuery
