import { useEffect, useState } from 'react'
import useVisibility from 'hooks/useVisibility'

const FIVE_MINUTE_IN_MS = 5 * 60 * 1000
let to

const useActivity = () => {
  const [isActive, setIsActive] = useState(true)
  const visibility = useVisibility()

  useEffect(() => {
    if (!visibility) {
      to = setTimeout(() => {
        setIsActive(false)
      }, FIVE_MINUTE_IN_MS)
    }
    if (visibility) {
      setIsActive(true)
      clearTimeout(to)
    }
  }, [visibility])

  return isActive
}

export default useActivity
