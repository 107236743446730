import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($id: String!, $email: String!) {
    removeMember(id: $id, email: $email) {
      id
      members {
        id
        email
      }
    }
  }
`

const useRemoveMemberMutation = options => {
  const [mutate, result] = useMutation(REMOVE_MEMBER, options)

  const removeMember = ({ id, email }) =>
    mutate({
      variables: {
        id,
        email
      }
    })

  return [removeMember, result]
}

export default useRemoveMemberMutation
