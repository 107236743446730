import React from 'react'
import { client } from 'config/apollo'
import { ApolloProvider } from '@apollo/react-hooks'
import Router from 'Router'
import GlobalStyle from 'GlobalStyle'
import { CurrentUserProvider } from 'components/CurrentUserContext'
import { NotificationsProvider } from 'components/Notifications/Context'
import Notifications from 'components/Notifications'
import useActivity from 'hooks/useActivity'
import './App.css'

const App = () => {
  const isActive = useActivity()

  return isActive ? (
    <ApolloProvider client={client}>
      <CurrentUserProvider>
        <NotificationsProvider>
          <Notifications />
          <GlobalStyle />
          <Router />
        </NotificationsProvider>
      </CurrentUserProvider>
    </ApolloProvider>
  ) : null
}

export default App
