import gql from 'graphql-tag'
import { useSubscription } from '@apollo/react-hooks'

export const APP_UPDATED = gql`
  subscription AppUpdatedSubscription($appId: String!) {
    appUpdated(appId: $appId) {
      id
      channels {
        id
      }
    }
  }
`

const useAppUpdatedSubscription = appId =>
  useSubscription(APP_UPDATED, {
    variables: { appId }
  })

export default useAppUpdatedSubscription
