export const NOTIFY = 'NOTIFY'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
export const HIDE_NOTIFICATION = 'REMOVE_NOTIFICATION'

let id = 1

const generateId = () => id++

export const notify = (type, message, options = {}) => ({
  type: NOTIFY,
  payload: {
    type,
    message,
    sticky: false,
    timeout: 5000,
    dismissable: true,
    hide: false,
    id: generateId(),
    onClick: () => {},
    onClose: () => {},
    ...options
  }
})

export const removeNotification = id => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    id
  }
})

export const hideNotification = id => ({
  type: HIDE_NOTIFICATION,
  payload: {
    id
  }
})
