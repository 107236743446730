import React from 'react'
import { Link } from 'react-router-dom'

const baseClassName = (color = 'purple') =>
  [
    'flex',
    `hover:bg-${color}-500`,
    `text-${color}-700`,
    'hover:text-white',
    'items-center',
    'justify-center',
    'border',
    `border-${color}-500`,
    'rounded'
  ].join(' ')

const getClassName = ({ size, className = '', color }) => {
  switch (size) {
    case 'xs':
      return `${className} ${baseClassName(color)} text-xs p-1`
    case 's':
      return `${className} ${baseClassName(color)} text-s p-1`
    case 'lg':
      return `${className} ${baseClassName(color)} text-lg p-3`
    default:
      return `${className} ${baseClassName(color)} text-base p-2`
  }
}

const Button = ({ color, size, className, ...props }) => (
  <button className={getClassName({ color, size, className })} {...props} />
)

export const ButtonLink = ({ color, size, className, ...props }) => (
  <Link className={getClassName({ color, size, className })} {...props} />
)

export default Button
