import React from 'react'

const Guide = () => {
  return (
    <div>
      <h3>guide</h3>

      <code>ha</code>
    </div>
  )
}

export default Guide
