import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import CurrentUserContext from 'components/CurrentUserContext'
import useChannelUpdatedSubscription from 'hooks/subscriptions/useChannelUpdated'
import useNotification from 'hooks/useNotification'

const CHANNEL_QUERY = gql`
  query ChannelQuery($channelId: String!) {
    channel(channelId: $channelId) {
      id
      deleted

      messages {
        id
        message
        createdAt
        userId
      }
    }
  }
`

const bubbleColor = ({ id: userId }, { id: currentUserId }) =>
  userId === currentUserId ? 'bg-blue-200 self-end' : 'bg-green-200 self-start'

const Messages = ({ channelId }) => {
  const { data: { channel: { deleted, messages = [] } = {} } = {} } = useQuery(
    CHANNEL_QUERY,
    {
      variables: {
        channelId
      },
      pollInterval: 60000
    }
  )
  const { currentUser } = useContext(CurrentUserContext)
  const { notify } = useNotification()

  useChannelUpdatedSubscription(channelId)

  if (deleted) {
    notify('info', `Channel #${channelId} archived.`)

    return <Redirect to="/dashboard" />
  }

  return (
    <div className="flex-1 overflow-y-scroll p-1 mb-2 border rounded-md border-purple-200">
      {messages
        .filter(msg => msg.userId)
        .map(({ id, message, userId }) => (
          <div key={id} className="flex flex-col mb-2">
            <span
              className={`rounded-full py-2 px-4 ${bubbleColor(
                { id: userId },
                currentUser
              )}`}
            >
              {message}
            </span>
          </div>
        ))}
    </div>
  )
}

export default Messages
