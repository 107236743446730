import React from 'react'
import IonIcon from './IonIcon'

const InfoCircleIcon = props => (
  <IonIcon {...props}>
    <title>Info Circle</title>
    <path d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z" />
    <polyline points="220 220 252 220 252 336" />
    <line x1="208" y1="340" x2="296" y2="340" />
    <path d="M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z" />
  </IonIcon>
)

export default InfoCircleIcon
