import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomePage from 'pages/Home'
import DashboardPage from 'pages/Dashboard'
import ChannelPage from 'pages/Channel'
import GuidePage from 'pages/Guide'
import SignInPage from 'pages/SignIn'
import ForceSignInPage from 'pages/ForceSignIn'
import SignUpPage from 'pages/SignUp'
import TokenAuth from 'pages/TokenAuth'
import NotFound from 'pages/NotFound'
import NewApp from 'pages/NewApp'
import AppSettings from 'pages/AppSettings'
import Installation from 'pages/Installation'
import { PageLoader } from 'components/Loader'
import CurrentUserContext from 'components/CurrentUserContext'
import ProtectedRoute from 'components/routes/ProtectedRoute'
import { isDevelopment } from 'utils'

const AppRouter = () => {
  const { loading } = useContext(CurrentUserContext)

  if (loading) {
    return <PageLoader text />
  }

  return (
    <Router>
      <Switch>
        <ProtectedRoute
          auth={false}
          redirectTo="/"
          path="/auth/:token"
          exact
          component={TokenAuth}
        />
        <ProtectedRoute
          auth={false}
          redirectTo="/dashboard"
          path="/"
          exact
          component={HomePage}
        />
        <ProtectedRoute
          redirectTo="/"
          path="/channel/:channelId"
          exact
          component={ChannelPage}
        />
        <ProtectedRoute
          redirectTo="/"
          path="/guide"
          exact
          component={GuidePage}
        />
        <ProtectedRoute
          auth={false}
          redirectTo="/"
          path="/sign-in"
          exact
          component={SignInPage}
        />
        {isDevelopment() && (
          <ProtectedRoute
            auth={false}
            redirectTo="/"
            path="/force-sign-in"
            exact
            component={ForceSignInPage}
          />
        )}
        <ProtectedRoute
          auth={false}
          redirectTo="/"
          path="/sign-up"
          exact
          component={SignUpPage}
        />
        <ProtectedRoute
          redirectTo="/"
          path="/apps/new"
          exact
          component={NewApp}
        />
        <ProtectedRoute
          redirectTo="/"
          path="/apps/:appId/settings"
          exact
          component={AppSettings}
        />
        <ProtectedRoute
          redirectTo="/"
          path={[
            '/dashboard',
            '/dashboard/:appId',
            '/apps/:appId',
            '/apps/:appId/channels/:channelId'
          ]}
          exact
          component={DashboardPage}
        />
        <Route path="/installation" exact component={Installation} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
}

export default AppRouter
