import { useEffect, useState } from 'react'

const useVisibility = () => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const handleVisibilityChangeStatus = visible =>
      setIsVisible(!document.hidden)

    window.addEventListener('visibilitychange', handleVisibilityChangeStatus)
  }, [])

  return isVisible
}

export default useVisibility
