import React from 'react'
import gql from 'graphql-tag'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import Messenger from 'components/Messenger'
import OptionsIcon from 'components/icons/Options'
import useAppUpdatedSubscription from 'hooks/subscriptions/useAppUpdated'
import useGetAppQuery from 'hooks/queries/useGetApp'

const LIST_ALL_APPS = gql`
  query ListAllApps {
    apps {
      id
      name
    }
  }
`

export const GET_APP = gql`
  query GetApp($id: String!) {
    app(id: $id) {
      id
      channels {
        id
      }
    }
  }
`

const getLastToken = id =>
  id
    .split('-')
    .map((token, idx, tokens) =>
      tokens.length - 1 === idx ? token : token[idx]
    )

const AppUpdatedSubscription = ({ appId }) => {
  useAppUpdatedSubscription(appId)
  return null
}

const AppSelect = ({ apps, loading, handleChange, ...props }) => {
  const { appId = '' } = useParams()

  return (
    <select
      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id="app-select"
      disabled={loading}
      onChange={handleChange}
      value={appId}
      {...props}
    >
      {loading ? (
        <option>Loading...</option>
      ) : (
        <>
          <option value="">Select an app</option>
          {apps.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </>
      )}
    </select>
  )
}

const Dashboard = () => {
  const { appId = '', channelId } = useParams()
  const { data: { apps = [] } = {}, loading: loadingApps } = useQuery(
    LIST_ALL_APPS,
    {
      fetchPolicy: 'network-only'
    }
  )
  const {
    data: { app: { channels = [], permissions } = {} } = {},
    loading: loadingChannels
  } = useGetAppQuery(appId, {
    skip: !appId,
    fetchPolicy: 'network-only'
  })
  const history = useHistory()
  const canEdit = permissions?.includes('edit')

  return (
    <Layout>
      {appId && <AppUpdatedSubscription appId={appId} />}
      <div className="flex-1 flex flex-col md:flex-row overflow-hidden">
        <div className="LeftSidebar md:max-w-200 border-solid md:border-r border-gray-600">
          <div className="LeftSidebar-Container">
            <div className="LeftSidebar-t px-3 py-2 md:py-3">
              <div className="flex-1">
                <label
                  className="block uppercase tracking-wide text-purple-700 text-xxxs font-bold mb-2"
                  htmlFor="app-select"
                >
                  Apps
                </label>
                <div className="flex md:flex-col items-center justify-center">
                  {!loadingApps && Boolean(apps.length) && (
                    <div className="relative flex-1 md:w-full">
                      <AppSelect
                        apps={apps}
                        loading={loadingApps}
                        handleChange={({ target: { value } }) =>
                          history.push(value ? `/apps/${value}` : '/dashboard')
                        }
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  )}
                  <div className="flex md:mt-2">
                    <Link
                      className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-1 px-4 border border-purple-500 hover:border-transparent rounded ml-1 md:ml-0"
                      to="/apps/new"
                    >
                      Create a new app
                    </Link>
                    {appId && canEdit && (
                      <Link
                        className="flex hover:bg-purple-500 text-purple-700 font-semibold hover:text-white items-center justify-center p-1 ml-1 border border-purple-500 rounded"
                        to={`/apps/${appId}/settings`}
                      >
                        <OptionsIcon size={14} />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="LeftSidebar-b px-3 py-2 md:py-3 md:border-t md:border-gray-600">
              <label className="block uppercase tracking-wide text-purple-700 text-xxxs font-bold mb-2">
                Channels
              </label>
              {loadingChannels || loadingApps ? (
                <Loader />
              ) : (
                <>
                  {channels.map(({ id }) => (
                    <div className="mb-1 hidden md:block" key={id}>
                      <Link
                        disabled={id === channelId}
                        className={`block w-full text-center hover:bg-purple-500 font-semibold py-1 px-4 border border-purple-500 hover:border-transparent rounded ${
                          id === channelId
                            ? 'bg-purple-500 text-white'
                            : 'bg-transparent text-purple-700 hover:text-white'
                        }`}
                        to={`/apps/${appId}/channels/${id}`}
                      >
                        {getLastToken(id)}
                      </Link>
                    </div>
                  ))}
                  <div className="md:hidden relative flex-1 md:w-full">
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="channel-select"
                      value={channelId}
                      disabled={loadingChannels || loadingApps}
                      onChange={e =>
                        history.push(
                          `/apps/${appId}/channels/${e.target.value}`
                        )
                      }
                    >
                      {loadingChannels || loadingApps ? (
                        <option>Loading...</option>
                      ) : (
                        channels.map(({ id }) => (
                          <option key={id} value={id}>
                            {getLastToken(id)}
                          </option>
                        ))
                      )}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-auto flex-1 p-3 md:p-4 flex items-center justify-center overflow-hidden">
          {channelId ? (
            <Messenger channelId={channelId} />
          ) : (
            'Select a channel..'
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Dashboard
