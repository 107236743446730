import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const DELETE_APP = gql`
  mutation DeleteApp($id: String!) {
    deleteApp(id: $id) {
      id
    }
  }
`

const useDeleteAppMutation = options => {
  const [mutate, result] = useMutation(DELETE_APP, options)

  const deleteApp = id =>
    mutate({
      variables: {
        id
      }
    })

  return [deleteApp, result]
}

export default useDeleteAppMutation
