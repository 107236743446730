import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

export const DropdownItem = props => (
  <Link
    className="block px-4 py-2 text-purple-700 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
    {...props}
  />
)

const Dropdown = ({ children, items }) => {
  const containerRef = useRef(null)
  const [open, setOpen] = useState(false)

  const close = useCallback(e => {
    if (!containerRef.current.contains(e.target)) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (open) {
      document.addEventListener('click', close)
    } else {
      document.removeEventListener('click', close)
    }
    return () => document.removeEventListener('click', close)
  }, [open, close])

  return (
    <div ref={containerRef}>
      {children({ open, setOpen })}
      {open && (
        <div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
          <div class="rounded-md bg-white shadow-xs">
            <div
              class="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {items}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
