import { NOTIFY, REMOVE_NOTIFICATION, HIDE_NOTIFICATION } from './actions'

const reducer = (state = [], action) => {
  switch (action.type) {
    case NOTIFY:
      return [...state, action.payload]
    case HIDE_NOTIFICATION:
      return state.map(
        notification =>
          notification.id === action.payload.id
            ? { ...notification, hide: true }
            : notification
      )
    case REMOVE_NOTIFICATION:
      return state.filter(notification => notification.id !== action.payload.id)
    default:
      return state
  }
}

export default reducer
