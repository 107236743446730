import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const JOIN_CHANNEL = gql`
  mutation JoinChannel($id: String!) {
    joinChannel(id: $id) {
      id
      userId
      messages {
        id
        message
      }
      users {
        id
      }
    }
  }
`

const useJoinChannelMutation = options => {
  const [mutate, result] = useMutation(JOIN_CHANNEL, options)

  const joinChannel = id =>
    mutate({
      variables: {
        id
      }
    }).catch(e => e)

  return [joinChannel, result]
}

export default useJoinChannelMutation
