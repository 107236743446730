import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const SEND_MESSAGE = gql`
  mutation SendMessage($channelId: String!, $message: String!) {
    sendMessage(channelId: $channelId, message: $message) {
      id
      message
      createdAt
      userId
    }
  }
`

const useSendMessageMutation = options => {
  const [mutate, result] = useMutation(SEND_MESSAGE, options)

  const sendMessage = ({ channelId, message }) =>
    mutate({
      variables: {
        channelId,
        message
      }
    })

  return [sendMessage, result]
}

export default useSendMessageMutation
