import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  z-index: 1200;
  display: flex;
  flex-direction: column-reverse;
  flex-flow: column-reverse;

  top: 55px;
  right: 28px;
`

Container.displayName = 'Notifications.Container'

export default Container
