import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import Layout from 'components/Layout'

const SIGN_UP = gql`
  mutation SignUp($email: String!) {
    signUp(email: $email)
  }
`

const notify = () => {}

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [signUp, { data: signUpData }] = useMutation(SIGN_UP, {
    onError: err => notify('error', err.message)
  })

  if (signUpData) {
    return (
      <Layout>
        <div className="bg-white shadow-xs w-11/12 max-w-xl rounded px-8 py-6 m-auto">
          Please check your email to verify.
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <form
        className="bg-white shadow-xs w-11/12 max-w-xl rounded px-8 py-6 m-auto"
        onSubmit={e => {
          e.preventDefault()
          signUp({ variables: { email } })
        }}
      >
        <div className="mb-4">
          <h3 className="text-base">Sign Up</h3>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="text-base md:text-xs bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="email"
            type="text"
            placeholder="Email"
            onChange={({ target: { value } }) => {
              setEmail(value)
            }}
          />
        </div>
        <div className="flex items-center justify-between">
          <button className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent rounded">
            Sign Up
          </button>
          <Link
            className="inline-block align-baseline font-bold text-xxs text-purple-500 hover:text-purple-800"
            to="/"
          >
            Cancel
          </Link>
        </div>
      </form>
    </Layout>
  )
}

export default SignIn
