import styled from 'styled-components'

const Message = styled.div`
  flex: 1;
  padding: 5px;
`

Message.displayName = 'Notification.Message'

export default Message
