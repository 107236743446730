import styled from 'styled-components'

const Container = styled.div`
  color: #333;
  background: #fff;
  width: 375px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 3px 8px 0 rgba(22, 38, 59, 0.2);
`

Container.displayName = 'Notification.Container'

export default Container
