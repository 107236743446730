import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const ADD_MEMBER = gql`
  mutation AddMember($id: String!, $email: String!) {
    addMember(id: $id, email: $email) {
      id
      channels {
        id
      }
      members {
        id
        email
      }
    }
  }
`

const useAddMemberMutation = options => {
  const [mutate, result] = useMutation(ADD_MEMBER, options)

  const addMember = ({ id, email }) =>
    mutate({
      variables: {
        id,
        email
      }
    })

  return [addMember, result]
}

export default useAddMemberMutation
