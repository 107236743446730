import React from 'react'
import Layout from 'components/Layout'

const Home = () => {
  return (
    <Layout transparent>
      <div className="m-auto text-sm text-purple-600">
        Annedesk is a live chat service.
      </div>
    </Layout>
  )
}

export default Home
