import React from 'react'
import InfoIcon from 'components/icons/InfoCircle'
import AlertIcon from 'components/icons/AlertCircle'
import CheckIcon from 'components/icons/CheckCircle'

const ICON_SIZE = '1.75rem'

const IconContainer = ({ children, color }) => (
  <div className={`text-${color}-600`}>{children}</div>
)

const Icon = ({ kind, renderIcon }) => {
  const props = { width: ICON_SIZE, height: ICON_SIZE }

  if (typeof renderIcon === 'function') {
    return <IconContainer>{renderIcon(props)}</IconContainer>
  }

  switch (kind) {
    case 'success':
      return (
        <IconContainer>
          <CheckIcon style={{ stroke: 'green' }} title="success" {...props} />
        </IconContainer>
      )
    case 'warning':
      return (
        <IconContainer>
          <AlertIcon style={{ stroke: 'orange' }} title="warning" {...props} />
        </IconContainer>
      )
    case 'info':
      return (
        <IconContainer>
          <InfoIcon style={{ stroke: 'blue' }} title="info" {...props} />
        </IconContainer>
      )
    case 'error':
      return (
        <IconContainer>
          <AlertIcon style={{ stroke: 'red' }} title="danger" {...props} />
        </IconContainer>
      )
    default:
      return null
  }
}

Icon.displayName = 'Notification.Icon'

export default Icon
