import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import Layout from 'components/Layout'
import TrashIcon from 'components/icons/Trash'
import Button, { ButtonLink } from 'components/Button'
import useNotification from 'hooks/useNotification'
import useUpdateAppMutation from 'hooks/mutations/useUpdateApp'
import useDeleteAppMutation from 'hooks/mutations/useDeleteApp'
import useGetAppQuery from 'hooks/queries/useGetApp'
import ManageMembers from './ManageMembers'

const AppSettings = () => {
  const { appId } = useParams()
  const {
    data: {
      app: { name: appName, hostname: appHostname, members, permissions } = {}
    } = {}
  } = useGetAppQuery(appId)
  const [name, setName] = useState('')
  const [hostname, setHostname] = useState('')
  const [updateApp, { loading: updateLoading }] = useUpdateAppMutation()
  const [deleteApp, { data: deleteData }] = useDeleteAppMutation()
  const { notify } = useNotification()
  const canEdit = permissions?.includes('edit')

  useEffect(() => {
    setName(appName)
    setHostname(appHostname)
  }, [appName, appHostname])

  if (permissions && !canEdit) {
    notify('error', 'You do not have permission to view that.')

    return <Redirect to="/" />
  }

  if (deleteData) {
    notify('info', 'Successfully deleted.')

    return <Redirect to="/" />
  }

  return (
    <Layout>
      <div className="bg-white w-11/12 max-w-xl m-auto">
        <form
          className="bg-white shadow-xs rounded px-8 py-6 my-2"
          onSubmit={async e => {
            e.preventDefault()
            await updateApp({ id: appId, name, hostname })
            notify('info', 'Successfully updated.')
          }}
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xs font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="text-base md:text-xs bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="name"
              type="text"
              placeholder="Name"
              value={name}
              onChange={({ target: { value } }) => {
                setName(value)
              }}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-xs font-bold mb-2"
              htmlFor="name"
            >
              hostname
            </label>
            <input
              className="text-base md:text-xs bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="hostname"
              type="text"
              placeholder="hostname"
              value={hostname}
              onChange={({ target: { value } }) => setHostname(value)}
            />
          </div>
          <div className="flex items-center justify-between">
            <Button className="w-full" disabled={updateLoading}>
              {updateLoading ? 'Updating...' : 'Update'}
            </Button>
          </div>
        </form>
        <ManageMembers members={members} />
        <div className="bg-white shadow-xs rounded px-8 py-6 my-2">
          <div className="flex items-center justify-between">
            <div>
              <p className="block text-gray-700 font-bold text-xs mb-2">
                Delete this app
              </p>
              <p className="block text-gray-700 text-xs mb-2">
                Once you delete an app, there is no going back. Please be
                certain.
              </p>
            </div>
            <Button
              color="red"
              onClick={() => {
                if (window.confirm('Do you really want to delete?')) {
                  deleteApp(appId)
                }
              }}
            >
              <TrashIcon size={18} className="mr-1 red-icon" />
              Delete
            </Button>
          </div>
        </div>
        <div className="my-2">
          <ButtonLink to={`/apps/${appId}`}>Back</ButtonLink>
        </div>
      </div>
    </Layout>
  )
}

export default AppSettings
