import React from 'react'
import CloseCircleIcon from 'components/icons/CloseCircle'

const CloseButton = props => (
  <button className="flex items-center justify-center p-2" {...props}>
    <CloseCircleIcon
      size={20}
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeMiterlimit: 10,
        strokeWidth: '32px'
      }}
    />
  </button>
)

export default CloseButton
