import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { Link, Redirect } from 'react-router-dom'
import Layout from 'components/Layout'
import useNotification from 'hooks/useNotification'
import { graphQLError } from 'utils'

const CREATE_APP = gql`
  mutation CreateApp($name: String!, $hostname: String!) {
    createApp(name: $name, hostname: $hostname) {
      id
      name
    }
  }
`

const NewApp = () => {
  const [name, setName] = useState('')
  const [hostname, setHostname] = useState('')
  const [protocol, setProtocol] = useState('https://')
  const { notify } = useNotification()
  const [createApp, { data, loading }] = useMutation(CREATE_APP, {
    onError: err => {
      if (err.message === graphQLError('reached_limit')) {
        notify('error', 'You have reached the limit of 3 apps.')
      }
    }
  })

  if (!loading && data) {
    return <Redirect to={`/apps/${data?.createApp?.id}`} />
  }

  return (
    <Layout>
      <form
        className="bg-white shadow-xs w-11/12 max-w-xl rounded px-8 pt-6 pb-8 m-auto"
        onSubmit={e => {
          e.preventDefault()
          createApp({ variables: { name, hostname: `${protocol}${hostname}` } })
        }}
      >
        <div className="mb-4">
          <h3 className="text-base">Create a new app</h3>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-xs font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="text-base md:text-xs bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="name"
            type="text"
            placeholder="Name"
            value={name}
            onChange={({ target: { value } }) => {
              setName(value)
            }}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-xs font-bold mb-2"
            htmlFor="name"
          >
            hostname
          </label>
          <div className="flex items-center">
            <div className="relative flex-1 mr-1">
              <select
                className="block text-base md:text-xs appearance-none bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={({ target: { value } }) => setProtocol(value)}
                value={protocol}
              >
                <option value="https://">https://</option>
                <option value="http://">http://</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <input
              className="text-base md:text-xs bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="hostname"
              type="text"
              placeholder="www.yoursite.com"
              value={hostname}
              onChange={({ target: { value } }) => setHostname(value)}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent rounded">
            Create
          </button>
          <Link
            className="inline-block align-baseline font-bold text-xxs text-purple-500 hover:text-purple-800"
            to="/"
          >
            Cancel
          </Link>
        </div>
      </form>
    </Layout>
  )
}

export default NewApp
