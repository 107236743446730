import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const UPDATE_APP = gql`
  mutation UpdateApp($id: String!, $name: String, $hostname: String) {
    updateApp(id: $id, name: $name, hostname: $hostname) {
      id
      name
      hostname
    }
  }
`

const useDeleteAppMutation = options => {
  const [mutate, result] = useMutation(UPDATE_APP, options)

  const updateApp = ({ id, name, hostname }) =>
    mutate({
      variables: {
        id,
        name,
        hostname
      }
    })

  return [updateApp, result]
}

export default useDeleteAppMutation
